import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AppURL from '../../api/AppURL';
import axios from 'axios';

const FooterContactWithOutEmail = React.lazy(() => import("./FooterContactWithOutEmail"))
const FooterAddressWithOutContact = React.lazy(() => import("./FooterAddressWithOutContact"))
const FooterWithEmail = React.lazy(() => import("./FooterWithEmail"))

const ContactPosition = React.lazy(() => import("./ContactPosition"))
const CopyRights = React.lazy(() => import("./CopyRights"))
const Logo = React.lazy(() => import("./Logo"))
const ContactDetails = React.lazy(() => import("./ContactDetails"))
const Socialmedia = React.lazy(() => import("./Socialmedia"))
const FooterAddress = React.lazy(() => import("./FooterAddress"))
const Subscribe = React.lazy(() => import("./Subscribe"))
const JavaScriptBottom = React.lazy(() => import("./JavaScriptBottom"))
const ScrollToTop = React.lazy(() => import("./ScrollToTop"))
const ApplyPosition = React.lazy(() => import("./ApplyPosition"))

class Footer extends Component {
    constructor() {
        super();
        this.state = {
            about_text: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetHomeAbout).then(response => {

            let JsonData = (response.data['Home About'])[0];
            this.setState({
                about_text: JsonData['about_text']

            });


        }).catch(error => {

        });
    }
    render() {
        return (
            <Fragment>
                <Suspense>
                    <div className="footer bg-image-3">
                        <Container>
                            <div className='footer-2'>
                                <Row>

                                    <Col lg={4} md={4} xl={4} xxl={4} sm={6} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                                <h4>Contact Us</h4>
                                                <FooterAddressWithOutContact />
                                            </Col>


                                        </Row>
                                    </Col>
                                    <Col lg={4} md={4} xl={4} xxl={4} sm={6} xs={12}>
                                        <h4>Call Us</h4>

                                        <FooterContactWithOutEmail />

                                    </Col>


                                    <Col lg={4} md={4} xl={4} xxl={4} sm={6} xs={12}>
                                        <h4>Mail Us</h4>
                                        <FooterWithEmail />


                                    </Col>

                                </Row>

                                <Row>
                                    <CopyRights />
                                </Row>
                            </div >
                        </Container >
                    </div>



                    <ScrollToTop />
                    <JavaScriptBottom />
                </Suspense>
            </Fragment >


        )
    }
}

export default Footer;	